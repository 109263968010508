import React from 'react';
import type { UseFormReturn } from 'react-hook-form';
import type { AnySchema } from 'yup';
import type { Owner } from '@api';
import type { DocumentFile } from '@common/inputs/FileInput';
import type { Values as BusinessDetailsFields } from '@components/page-steps/BusinessDetailsPage';
import type { Values as ProcessingPageFields } from '@components/page-steps/ProcessingPage';
import type { Values as ApplicationProcessingFields } from '@components/page-steps/ApplicationProcessPage';
import type { Values as ManagingFields } from '@components/page-steps/ManagingDetailsPage';
import type { Values as BankPageFields } from '@components/page-steps/BankDetailsPage';
import type { Values as EquipmentFields } from '@components/page-steps/EquipmentPage';
import type { Values as SignPageFields } from '@components/page-steps/SignPage';
import type { SurchargeAddendumFields } from '@components/page-steps/sign-page-components/SurchargeAddendum';
import type { NextNavigateProps } from '@hooks/useNavigationContext';
import type { ApplicationUpdate, FileType } from '../types';

export type WizardContextType = {
    steps: WizardStep[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: UseFormReturn<AllFields>;
    isSigner?: boolean;
    isSaving?: boolean;
    isSubmitted?: boolean;
    isOpportunity?: boolean;
    showBackToReview?: boolean;
    isApplication?: boolean;
    loading: boolean;
    completedSteps: number;
    onComplete: (values: any) => Promise<void>;
    onAutoSave?: (patch: ApplicationUpdate) => Promise<void>;
    onFileRemoval: (fileId: string) => Promise<void>;
    onFileUpload: (file: File, type?: FileType) => Promise<{ id: string, url: string }>;
    onOwnerAdd: (owner?: Omit<Owner, 'id'>) => Promise<{ id: string }>;
    onOwnerRemoval: (ownerId: string) => Promise<void>;
    id: string;
    setIsSaving?: (val: boolean) => void;
    getCurrentStep: () => WizardStep | undefined;
    setLoading: (loading: boolean) => void;
    setShowBackToReview: (val: boolean) => void;
    pdfComponent: (values: AllFields) => JSX.Element;
};

export type WizardProps = {
    children: React.ReactNode;
    hasEIServerOrCloud: boolean;
    hasEmergepay: boolean;
    isOpportunity?: boolean;
    isApplication?: boolean;
    isSalesUser?: boolean;
    isSigner?: boolean;
    isSubmitted?: boolean;
    onComplete?: (values: AllFields) => Promise<void>;
    id: string;
    onAutoSave?: (patch: ApplicationUpdate) => Promise<void>;
    onFileRemoval?: (fileId: string) => Promise<void>;
    onFileUpload?: (file: File, type?: FileType) => Promise<{ id: string, url: string }>;
    onOwnerAdd?: () => Promise<{ id: string }>;
    onOwnerRemoval?: (ownerId: string) => Promise<void>;
    pdfComponent?: (values: AllFields) => JSX.Element;
    showFooter?: boolean;
    steps: {
        name: string,
        title: string,
        fields: readonly string[],
        schema: AnySchema,
        children: React.ReactNode,
    }[],
    values?: { [key: string]: any } | null;
};

export type WizardStep = {
    fields?: string[],
    onNext?: () => Promise<NextNavigateProps> | NextNavigateProps;
    schema?: AnySchema;
    name: string;
    footer?: {
        enabled?: boolean;
        nextButtonDisabled?: boolean;
        buttonSteps?: (string | JSX.Element)[];
        className?: string;
        nextBtnLabel?: string;
    };
    children?: React.ReactNode;
    title: string;
};

export enum BusinessFields {
    DBANAME = 'dbaName',
    LEGALNAME = 'legalName',
    B_ADD1 = 'businessAddr1',
    B_ADD2 = 'businessAddr2',
    B_CITY = 'businessCity',
    B_STATE = 'businessState',
    B_ZIPCODE = 'businessZipCode',
    L_ADD1 = 'legalAddr1',
    L_ADD2 = 'legalAddr2',
    L_CITY = 'legalCity',
    L_STATE = 'legalState',
    L_ZIPCODE = 'legalZipCode',
    IS_LEGAL_SAME_AS_PYSICAL = 'legalIsPhysicalAddress',
    B_TAX_METHOD = 'businessTaxPayMethod',
    B_EMAIL = 'businessEmail',
    B_PHONE = 'businessPhone',
    ENTITY_TYPE = 'entityType',
    TIN = 'tin',
    DOE = 'dateOfEstb',
    DOT = 'targetDate',
    NO_OF_LOCATIONS = 'locationCount',
    B_WEBSITE = 'businessUrl',
}

export enum ProcessingFields {
    MARKETING_WEBSITE = 'marketingWebsite',
    MARKETING_FILE = 'marketingFile',
    TXN_COMPLETE_PERIOD = 'transactionCompletePeriod',
    TXN_COMPLETE_PERIOD_OTHER = 'transactionCompletePeriodOther',
    DAYS_TO_RETURN_CANCEL = 'daysToReturnOrCancel',
    IS_GS_DELIVERY_AFTER_TXN_DAY = 'isGSDeliveryAfterTxnDay',
    DELIVERY_IMMEDIATE = 'delivery_immediate_Percentage',
    DELIVERY_1_3_DAYS = 'delivery_1_3_DaysPercentage',
    DELIVERY_4_7_DAYS = 'delivery_4_7_DaysPercentage',
    DELIVERY_8_14_DAYS = 'delivery_8_14_DaysPercentage',
    DELIVERY_15_30_DAYS = 'delivery_15_30_DaysPercentage',
    DELIVERY_OVER_30_DAYS = 'delivery_Over_30_DaysPercentage',
    RECURRING_BILLING = 'recurringMonthly',
    BILLING_PERIOD = 'billingPeriod',
    DB_COLLECTING_CC_NUMBERS = 'entireCreditCardCollect',
    PCI_COMPLIANT = 'PCICompliant',
    DOES_CUS_SIGN_CONTRACT = 'contractSign',
    CONTRACT_FILES = 'contractFiles',
    HOW_CUSTOMER_ORDER = 'howCustomerOrder',
    VENDOR_PUR_ADDRESS = 'vendorPurchaseAddress',
    IS_CNP_REQUIRED = 'cnpAgreementRequired',
    SERVICE_FOR_CC_HOLDERS = 'serviceForCardHolder',
    AVG_TRANSACTION_SIZE = 'avgTransactionSize',
    HIGH_TICKET_VOLUME = 'high_ticket_volume',
    IS_ACCEPTING_CC_CARDS = 'isCurrentlyAcceptingCreditCards',
    EXPECTED_MONTHLY_CC_VOLUME = 'expectedMonthlyCCVolume',
    MOTO_PERCENT = 'motoPercentage',
    INTERNET_PERCENT = 'internetPercentage',
    KEYED_PERCENT = 'keyedPercentage',
    SWIPED_PERCENT = 'swipedPercentage',
    IS_SEASONAL = 'isSeasonal',
    SEASONAL_FROM = 'seasonalFrom',
    SEASONAL_TO = 'seasonalTo',
}

export enum BankFields {
    P_BANK_ROUTING_NUMBER = 'primaryBankRoutingNumber',
    P_BANK_ACCOUNT_NUMBER = 'primaryBankAcNumber',
    P_BANK_ACCOUNT_FULL_NAME = 'primaryBankAcHolderFullName',
    P_ACC_BANK_NAME = 'primaryAccountBankName',
    P_ACC_DDA_TYPE = 'primaryAccountDDAType',
    P_STATEMENT_1 = 'processingStatement1',
    P_STATEMENT_2 = 'processingStatement2',
    P_STATEMENT_3 = 'processingStatement3',
    P_BANK_ACC_FILE = 'primaryAcFile',
    EXTRA_FILES = 'extraFiles',
    WAIVE_PERSONAL_GUARANTEE = 'waivePersonalGuarantee',
    GUARANTEE_STATEMENT1 = 'guaranteeStatement1',
    GUARANTEE_STATEMENT2 = 'guaranteeStatement2',
    GUARANTEE_STATEMENT3 = 'guaranteeStatement3',
    GUARANTEE_B_SHEET = 'guaranteeBalanceSheet',
    GUARANTEE_PL_STATEMENT = 'guaranteePLStatement',
    MASKED_P_BANK_ACCOUNT_NUMBER = 'maskedPrimaryBankAcNumber',
    USE_ALTERNATIVE_ACCOUNT = 'useAlternativeAccount',
    ALT_BANK_ROUTING_NUMBER = 'altBankRoutingNumber',
    ALT_BANK_ACCOUNT_NUMBER = 'altBankAcNumber',
    ALT_BANK_ACCOUNT_FULL_NAME = 'altBankAcHolderFullName',
    ALT_ACC_BANK_NAME = 'altAccountBankName',
    ALT_ACC_DDA_TYPE = 'altAccountDDAType',
    ALT_BANK_ACC_FILE = 'altAcFile',
    MASKED_ALT_BANK_ACCOUNT_NUMBER = 'maskedAltBankAcNumber',
}

export enum SignFields {
    TIMESTAMP = 'timestamp',
    IP_ADDRESS = 'ipAddress',
    SIGNATURE = 'keyedInSignature',
    SIGNER_NAME = 'signerName',
    FORMATTED_TIMESTAMP = 'formattedTimestamp'
}

export enum EquipmentSetUpFields {
    SHIPPING_ADD1 = 'shippingAddr1',
    SHIPPING_ADD2 = 'shippingAddr2',
    SHIPPING_CITY = 'shippingCity',
    SHIPPING_STATE = 'shippingState',
    SHIPPING_ZIPCODE = 'shippingZipCode',
    IS_SHIPPING_SAME_AS_PHYSICAL = 'shippingIsPhysicalAddress',
    SHIPPING_PHONE = 'shippingPhone',
    INSTALL_CONTACT_FIRST_NAME = 'installContactFirstName',
    INSTALL_CONTACT_LAST_NAME = 'installContactLastName',
    INSTALL_CONTACT_PHONE = 'installContactPhone',
    INSTALL_CONTACT_EMAIL = 'installContactEmail',
    UTILIZE_TIPS = 'utilizeTips',
    UTILIZE_CARD_STORAGE = 'utilizeCardStorage',
    WINDOWS_TEN_OR_NEWER = 'windowsTenOrNewer',
    ETHERNET_AVAILABLE = 'ethernetAvailable',
    FEET_CC_MACHINE_TO_ROUTER = 'feetfromCreditCardMachineToRouter',
    FIVE_PORT_SWITCH_PURCHASE = 'fivePortSwitchPurchase',
    POS_ACCESS = 'posAccess',
    HAND_KEY_TRANSACTIONS = 'handKeyTransactions',
    NO_OF_COMPUTERS = 'numberOfComputers',
    IS_ACCEPTING_GIFT_CARDS = 'isAcceptingGiftCards',
    GIFT_CARD_PROVIDER = 'giftCardProvider',
    BALANCE_SHEET_PROVIDED = 'balanceSheetProvided',
    BLANK_GIFT_CARD_PROVIDED = 'blankGiftCardProvided',
    IS_INTERESTED_GIFT_CARDS = 'isInterestedAcceptingGiftCards',
    NO_OF_WORK_STATIONS = 'numberOfWorkStations',
    INSTALL_ADMIN_ACCESS = 'adminAccessDuringInstall',
    REPORTING_TRAINING = 'reportingTraining',
    NO_FOR_REPORTING_TRAINING = 'numberForReportingTraining',
    PROCESSING_DATE = 'processingDate',
}

export enum AddendumFields {
    DEBIT_CARD_BILLING = 'debitCardBilling'
}

// TODO(BL): remove "contact" from type once we finish replacing application.contact with application.people[i]
export type AllFields =
    BusinessDetailsFields
    & ApplicationProcessingFields
    & ProcessingPageFields
    & ManagingFields
    & BankPageFields
    & EquipmentFields
    & SurchargeAddendumFields
    & SignPageFields
    & {
    contact?: {
        Addr1: string,
        Addr2: string,
        City: string,
        ContactNumber: string,
        DateOfBirth?: string,
        Email: string,
        FirstName: string,
        LastName: string,
        SSN?: string,
        IsOwner?: string;
        PercentOwnership?: string,
        State?: { label: string, value: string },
        Title?: { label: string, value: string },
        ZipCode: string,
        isPrimaryLocSameAsBusiness: string;
    },
    opportunityStage: string
};

// Used to map fields for the Opportunity path
export const mapFields = (fields: AllFields, { hasEIServerOrCloud, hasEmergepay }: { hasEIServerOrCloud: boolean, hasEmergepay: boolean }) => {
    const address = {
        line1: fields.businessAddr1,
        line2: fields.businessAddr2,
        city: fields.businessCity,
        state: fields.businessState?.value,
        postalCode: fields.businessZipCode,
    };
    const legalAddress = fields.legalIsPhysicalAddress === 'yes' ? address : {
        line1: fields.legalAddr1,
        line2: fields.legalAddr2,
        city: fields.legalCity,
        state: fields.legalState?.value,
        postalCode: fields.legalZipCode,
    };

    const isSoleProp = fields.entityType?.value === 'Sole Proprietorship';
    const people = isSoleProp ? [{
        address: {
            line1: fields.people?.[0].Addr1,
            line2: fields.people?.[0].Addr2,
            city: fields.people?.[0].City,
            state: fields.people?.[0].State?.value,
            postalCode: fields.people?.[0]?.ZipCode,
        },
        dob: fields.people?.[0].DateOfBirth,
        email: fields.people?.[0].Email,
        firstName: fields.people?.[0].FirstName,
        lastName: fields.people?.[0].LastName,
        percentOwnership: 100,
        phone: fields.people?.[0].ContactNumber,
        ...(fields.people?.[0].SSN && !fields.people?.[0].SSN?.includes('*') ? { ssn: fields.people[0].SSN } : {}),
        title: fields.people?.[0].Title?.value,
        hasBusinessAddress: fields.people?.[0].isPrimaryLocSameAsBusiness,
    }] : fields.people?.map(person => ({
        address: {
            line1: person?.Addr1,
            line2: person?.Addr2,
            city: person?.City,
            state: person?.State?.value,
            postalCode: person?.ZipCode,
        },
        dob: person.DateOfBirth,
        email: person.Email,
        firstName: person.FirstName,
        lastName: person.LastName,
        percentOwnership: person.PercentOwnership,
        phone: person.ContactNumber,
        ...(person.SSN && !person.SSN?.includes('*') ? { ssn: person.SSN } : {}),
        title: person.Title?.value,
        hasBusinessAddress: person.isPrimaryLocSameAsBusiness,
    }));

    const files = [{ ...(fields.primaryAcFile as DocumentFile), type: 'Voided Check' }];

    fields?.people?.forEach(person => {
        if (person?.PhotoID) {
            files.push({ ...person.PhotoID, name: `${person.FirstName}-${person.LastName}-${person.PhotoID.name}` ,type: 'Internal File' });
        }
    })
    if (fields?.extraFiles?.length) {
        files.push(...fields.extraFiles.map(({ file }) => ({ ...file, type: 'Other' })));
    }
    if (fields.contractFiles) {
        files.push({ ...fields.contractFiles, type: 'Other' });
    }
    if (fields.marketingFile) {
        files.push({ ...fields.marketingFile, type: 'Other' });
    }
    if (fields.altAcFile) {
        files.push({ ...(fields.altAcFile as DocumentFile), type: 'Voided Check' })
    }
    if (fields.waivePersonalGuarantee === 'yes') {
        if (fields[BankFields.GUARANTEE_STATEMENT1]) {
            files.push({ ...fields[BankFields.GUARANTEE_STATEMENT1] as DocumentFile, type: 'Financials' })
        }
        if (fields.guaranteeStatement2) {
            files.push({ ...fields.guaranteeStatement2 as DocumentFile, type: 'Financials' })
        }
        if (fields.guaranteeStatement3) {
            files.push({ ...fields.guaranteeStatement3 as DocumentFile, type: 'Financials' })
        }
        if (fields.guaranteeBalanceSheet) {
            files.push({ ...fields.guaranteeBalanceSheet as DocumentFile, type: 'Financials' })
        }
        if (fields.guaranteePLStatement) {
            files.push({ ...fields.guaranteePLStatement as DocumentFile, type: 'Financials' })
        }
    }

    return {
        banking: {
            ...(fields.primaryBankAcNumber && !fields.primaryBankAcNumber.includes('*') ? { depositAccount: fields.primaryBankAcNumber } : {}),
            depositRouting: fields[BankFields.P_BANK_ROUTING_NUMBER],
            isPersonalGuarantee: fields[BankFields.WAIVE_PERSONAL_GUARANTEE] === 'no',
            ...(fields.altBankAcNumber && !fields.altBankAcNumber.includes('*') ? { alternativeAccount: fields.altBankAcNumber } : {}),
            alternativeRouting: fields[BankFields.ALT_BANK_ROUTING_NUMBER],
            pullFeesFromAlternativeAccount: fields[BankFields.USE_ALTERNATIVE_ACCOUNT]?.toLowerCase() === 'yes',
        },
        business: {
            address,
            dba: fields.dbaName,
            email: fields.businessEmail,
            phone: fields.businessPhone,
            websiteUrl: fields.businessUrl,
        },
        closeDate: fields.targetDate,
        cnpAgreement: fields[ProcessingFields.IS_CNP_REQUIRED] === 'yes' ? {
            creditAuthDuration: fields[ProcessingFields.TXN_COMPLETE_PERIOD]?.value,
            otherCreditAuthDuration: fields[ProcessingFields.TXN_COMPLETE_PERIOD_OTHER],
            doesCustomerSignContract: fields[ProcessingFields.DOES_CUS_SIGN_CONTRACT] === 'yes',
            howCustomerOrderProduct: fields[ProcessingFields.HOW_CUSTOMER_ORDER],
            pciCompliant: fields[ProcessingFields.PCI_COMPLIANT]?.value !== '' ? fields[ProcessingFields.PCI_COMPLIANT]?.value === 'yes' : null,
            productAdvertisedOn: fields[ProcessingFields.MARKETING_WEBSITE], // TODO(LY) Confirm this is correct
            productServiceProvided: fields[ProcessingFields.SERVICE_FOR_CC_HOLDERS],
            recurringBillingDuration: fields[ProcessingFields.BILLING_PERIOD]?.value ?? null,
            storesCardNumbers: fields[ProcessingFields.DB_COLLECTING_CC_NUMBERS] === 'yes',
            vendorNamesAndAddress: fields[ProcessingFields.VENDOR_PUR_ADDRESS],
        } : null,
        delivery: {
            delay_1_3_days: fields.delivery_1_3_DaysPercentage,
            delay_4_7_days: fields.delivery_4_7_DaysPercentage,
            delay_8_14_days: fields.delivery_8_14_DaysPercentage,
            delay_15_30_days: fields.delivery_15_30_DaysPercentage,
            delay_over_30_days: fields.delivery_Over_30_DaysPercentage,
            immediate: fields.delivery_immediate_Percentage,
            refundPolicyInDays: fields.daysToReturnOrCancel,
            isGSDeliveryAfterTxnDay: fields[ProcessingFields.IS_GS_DELIVERY_AFTER_TXN_DAY]
        },
        files,
        legalEntity: {
            address: legalAddress,
            dateOfIncorporation: fields.dateOfEstb,
            name: fields.legalName,
            tin: `${fields.tin}`,
            tinType: fields.businessTaxPayMethod,
            type: fields.entityType?.value,
        },
        locationCount: fields.locationCount,
        people,
        shippingDetails: fields.shippingIsPhysicalAddress === 'yes' ?
            `Address Line 1: ${fields.businessAddr1}
            Address Line 2: ${fields.businessAddr2}
            City: ${fields.businessCity}
            State: ${fields.businessState?.value}
            Zip Code: ${fields.businessZipCode}
            Phone: ${fields.businessPhone}`
            :
            `Address Line 1: ${fields.shippingAddr1}
            Address Line 2: ${fields.shippingAddr2}
            City: ${fields.shippingCity}
            State: ${fields.shippingState?.value}
            Zip Code: ${fields.shippingZipCode}
            Phone: ${fields.shippingPhone}`,
        installNotes:
            `Install / Training Point of Contact First Name: ${fields[EquipmentSetUpFields.INSTALL_CONTACT_FIRST_NAME]}
            Install / Training Point of Contact Last Name: ${fields[EquipmentSetUpFields.INSTALL_CONTACT_LAST_NAME]}
            Install / Training Point of Contact Phone: ${fields[EquipmentSetUpFields.INSTALL_CONTACT_PHONE]}
            Install / Training Point of Contact Email: ${fields[EquipmentSetUpFields.INSTALL_CONTACT_EMAIL]}
            ${(hasEIServerOrCloud || hasEmergepay) ? `
                Will you be utilizing tips?: ${fields[EquipmentSetUpFields.UTILIZE_TIPS]}
            ` : ``}
            ${hasEIServerOrCloud ? `
                Do you plan to utilize card storage?: ${fields[EquipmentSetUpFields.UTILIZE_CARD_STORAGE]}
                Can you confirm that your computer is currently running Windows 10 or newer?: ${fields[EquipmentSetUpFields.WINDOWS_TEN_OR_NEWER]}
                Is there a wired internet connection (ethernet) available?: ${fields[EquipmentSetUpFields.ETHERNET_AVAILABLE]}
                Feet from credit card machine to modem/router: ${fields[EquipmentSetUpFields.FEET_CC_MACHINE_TO_ROUTER]}
                Would you like to purchase a 5-port switch ($21) from Gravity Payments?: ${fields[EquipmentSetUpFields.FIVE_PORT_SWITCH_PURCHASE]}
                Do you remote into a server to access the Point of Sale?: ${fields[EquipmentSetUpFields.POS_ACCESS]}
                Would you like the ability to hand-key transactions on any other computers without a physical terminal?: ${fields[EquipmentSetUpFields.HAND_KEY_TRANSACTIONS]}
                How many computers?: ${fields[EquipmentSetUpFields.NO_OF_COMPUTERS]}
                Do you currently accept gift cards?: ${fields[EquipmentSetUpFields.IS_ACCEPTING_GIFT_CARDS]}
                Current Gift Card Provider: ${fields[EquipmentSetUpFields.GIFT_CARD_PROVIDER]}
                Balance Sheet Provided to Gravity: ${fields[EquipmentSetUpFields.BALANCE_SHEET_PROVIDED]}
                Blank Gift Card Provided to Gravity: ${fields[EquipmentSetUpFields.BLANK_GIFT_CARD_PROVIDED]}
                Are you interested in accepting gift cards?: ${fields[EquipmentSetUpFields.IS_INTERESTED_GIFT_CARDS]}
                How many workstations (terminal and hand-key) will process cards with Gravity Payments?: ${fields[EquipmentSetUpFields.NO_OF_WORK_STATIONS]}
                During the install, will somebody have admin access (usernames/passwords) to the workstations?: ${fields[EquipmentSetUpFields.INSTALL_ADMIN_ACCESS]}
            ` : ``}
            ${(hasEIServerOrCloud || hasEmergepay) ? `Is there more than one person (or a different person) who will need training on reporting?: ${fields[EquipmentSetUpFields.REPORTING_TRAINING]}
            How many people will need training?: ${fields[EquipmentSetUpFields.NO_FOR_REPORTING_TRAINING]}
            When do you anticipate processing your first transaction with Gravity Payments?: ${fields[EquipmentSetUpFields.PROCESSING_DATE]}` : ``}`,
        equipmentSetUpFields: {
            shippingIsPhysicalAddress: fields.shippingIsPhysicalAddress,
            shippingAddr1: fields.shippingAddr1,
            shippingAddr2: fields.shippingAddr2,
            shippingCity: fields.shippingCity,
            shippingState: fields.shippingState?.value,
            shippingZipCode: fields.shippingZipCode,
            shippingPhone: fields.shippingPhone,
            installContactFirstName: fields[EquipmentSetUpFields.INSTALL_CONTACT_FIRST_NAME],
            installContactLastName: fields[EquipmentSetUpFields.INSTALL_CONTACT_LAST_NAME],
            installContactPhone: fields[EquipmentSetUpFields.INSTALL_CONTACT_PHONE],
            installContactEmail: fields[EquipmentSetUpFields.INSTALL_CONTACT_EMAIL],
            utilizeTips: fields[EquipmentSetUpFields.UTILIZE_TIPS],
            utilizeCardStorage: fields[EquipmentSetUpFields.UTILIZE_CARD_STORAGE],
            windowsTenOrNewer: fields[EquipmentSetUpFields.WINDOWS_TEN_OR_NEWER],
            ethernetAvailable: fields[EquipmentSetUpFields.ETHERNET_AVAILABLE],
            feetCCMachineToRouter: fields[EquipmentSetUpFields.FEET_CC_MACHINE_TO_ROUTER],
            fivePortSwitchPurchase: fields[EquipmentSetUpFields.FIVE_PORT_SWITCH_PURCHASE],
            posAccess: fields[EquipmentSetUpFields.POS_ACCESS],
            handKeyTransactions: fields[EquipmentSetUpFields.HAND_KEY_TRANSACTIONS],
            noOfComputers: fields[EquipmentSetUpFields.NO_OF_COMPUTERS],
            isAcceptingGiftCards: fields[EquipmentSetUpFields.IS_ACCEPTING_GIFT_CARDS],
            giftCardProvider: fields[EquipmentSetUpFields.GIFT_CARD_PROVIDER],
            balanceSheetProvided: fields[EquipmentSetUpFields.BALANCE_SHEET_PROVIDED],
            blankGiftCardProvided: fields[EquipmentSetUpFields.BLANK_GIFT_CARD_PROVIDED],
            isInterestedGiftCards: fields[EquipmentSetUpFields.IS_INTERESTED_GIFT_CARDS],
            noOfWorkStations: fields[EquipmentSetUpFields.NO_OF_WORK_STATIONS],
            installAdminAccess: fields[EquipmentSetUpFields.INSTALL_ADMIN_ACCESS],
            reportingTraining: fields[EquipmentSetUpFields.REPORTING_TRAINING],
            noForReportingTraining: fields[EquipmentSetUpFields.NO_FOR_REPORTING_TRAINING],
            processingDate: fields[EquipmentSetUpFields.PROCESSING_DATE],
        }
    };
}
export type OpportunityApplication = ReturnType<typeof mapFields>;
